import React from "react";
import "./certification.css";
import certificationImage from "../../assets/certification-1.jpg";
const Certification = () => {
  return (
    <>
      <section
        className="certification row align-items-center section"
        id="certification"
      >
        <h2 className="section_title">Certifications</h2>
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <div className="my-3 my-lg-0">
            <img
              src={certificationImage}
              alt=""
              className="certification-image"
            />
          </div>
          <div className="mt-lg-5 my-3 my-lg-0">
            <p className="certification-details">
              I completed a 16-week front-end development course at Besant
              Technologies in Chennai, where I learned HTML, CSS, and JavaScript
              to create responsive and dynamic web pages. Throughout the course,
              I developed proficiency in front-end development tools and
              frameworks like Bootstrap and ReactJs. By completing this course,
              I gained a strong foundation in front-end development and acquired
              the skills necessary to create engaging and user-friendly web
              experiences.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Certification;
