export const textVariant = (delay) => {
  return {
    hidden: {
      y: -50,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 1.25,
        delay: delay,
      },
    },
  };
};
export const child = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      damping: 5,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0,
    transition: {
      type: "spring",
      delay: 2,
      damping: 12,
      stiffness: 100,
    },
  },
};

export const fadeIn = {
  hidden: {
    opacity: 0,
    scale: 0,
    // x: 200,
  },
  visible: (i = 1) => ({
    opacity: 1,
    // x: 0,
    scale: 1,
    transition: { staggerChildren: 0.18, delayChildren: 0.2 * i },
  }),
};
export const desc = {
  hidden: {
    y: 40,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 4,
    },
  },
};
export const swing = {
  visible: {
    y: [0, -13, 0],
    scale: [1, 1.05, 1],
    transition: {
      type: "spring",
      duration: 4,
      stiffness: 100,
      damping: 12,
      repeat: Infinity,
    },
  },
};
export const zoomIn = (delay, duration) => {
  return {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    show: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "tween",
        delay: delay,
        duration: duration,
        ease: "easeOut",
      },
    },
  };
};

export const slideIn = (direction, type, delay, duration) => {
  return {
    hidden: {
      x: direction === "left" ? "-100%" : direction === "right" ? "100%" : 0,
      y: direction === "up" ? "100%" : direction === "down" ? "100%" : 0,
    },
    show: {
      x: 0,
      y: 0,
      transition: {
        type: type,
        delay: delay,
        duration: duration,
        ease: "easeOut",
      },
    },
  };
};

export const staggerContainer = (staggerChildren, delayChildren) => {
  return {
    hidden: {},
    show: {
      transition: {
        staggerChildren: staggerChildren,
        delayChildren: delayChildren || 0,
      },
    },
  };
};
