import React from "react";
import { skills } from "../../data/SkillsData";
import { motion } from "framer-motion";
// import { swing } from "../../utils/motion";
import "./skills.css";
const Skills = () => {
  return (
    <>
      <section className="skills section" id="skills">
        <h2 className="section_title">Skills</h2>
        <span className="section_subtitle">My Technical level</span>

        <div className="d-flex flex-row flex-wrap align-items-center justify-content-center gap-10 skils-wrapper">
          {skills.map((technology) => (
            <motion.div
              initial={{ opacity: 1, y: 0 }}
              animate={{
                y: [0, 20, 0],
                scale: [1, 0.8, 1],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                stiffness: 100,
                damping: 12,
                repeatType: "reverse",
              }}
              // animate="visible"
              className="w-20 h-20 p-2 flex justify-center items-center  rounded-full bg-[#fbf2ff]"
              key={skills.name}
            >
              <div cascade duration={2000}>
                <img className="technology" alt="img" src={technology.icon} />
              </div>
            </motion.div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Skills;
