/* eslint-disable jsx-a11y/anchor-is-valid */
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./header.css";
import React from "react";
// import { Link } from "react-router-dom";
function Header() {
  return (
    <>
      <Navbar  expand="lg" className="position-sticky top-0 p-3 bg-light header">
        <Container>
          <Navbar.Brand href="#home" className="navbar-list"> Pravin Amalan</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home" className="navbar-list">
                <i className="bx bx-home mx-2"></i>
                Home
              </Nav.Link>
              <Nav.Link href="#about" className="navbar-list">
                <i className="bx bx-user mx-2"></i>
                About
              </Nav.Link>
              <Nav.Link href="#skills" className="navbar-list">
                <i className="bx bx-file mx-2"></i>
                Skills
              </Nav.Link>
              <Nav.Link href="#projects" className="navbar-list">
                <i className="bx bx-briefcase mx-2"></i>
                Projects
              </Nav.Link>
              <Nav.Link href="#certification" className="navbar-list">
                <i className="bx bx-image-alt mx-2"></i>
                Certification
              </Nav.Link>
              <Nav.Link href="#contact" className="navbar-list">
                <i className="bx bxl-telegram mx-2"></i>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
