import React from "react";
import "./projects.css";
import WorkCard from "./WorkCard";
import WorkCardData from "../../data/ProjectData";
import { desc } from "../../utils/motion";
import { motion } from "framer-motion";

const Projects = () => {
  return (
    <div className="work-container section" id="projects">
      {" "}
      <motion.div
        variants={desc}
        initial="hidden"
        whileInView="visible"
        className="project-heading"
      >
        <h1 className="project-heading">Projects</h1>{" "}
        <h6>Here are some of my Projects</h6>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, transform: "translateX(-20vw)" }}
        whileInView={{ opacity: 1, transform: "translateX(0vw)" }}
        transition={{ duration: 2.5, type: "spring" }}
        className="project-container"
      >
        {" "}
        {WorkCardData.map((val, ind) => {
          return (
            <WorkCard
              key={ind}
              imgsrc={val.imgsrc}
              title={val.title}
              text={val.text}
              view={val.view}
            />
          );
        })}{" "}
      </motion.div>{" "}
    </div>
  );
};

export default Projects;
