import "./projects.css";
import React from "react";
const WorkCard = (props) => {
  return (
    <div className="project-card">
      <div className="content">
        <div class="content-overlay"></div>
        <img src={props.imgsrc} alt="img" className="content-image"/>
        <div class="content-details fadeIn-bottom">
          <h3 className="project-title">{props.title}</h3>
          <p>{props.text}</p>
        </div>
      </div>
      <div className="pro-btns my-lg-3">
        <a
          href={props.view}
          className="button button--flex text-decoration-none"
          target="_blak"
        >
          View project
        </a>
      </div>
    </div>
  );
};
export default WorkCard;
