import React from "react";
import './home.css';

const Social = () => {
  return (
    <div className="home_social mx-4 mx-lg-0">
      <a href="https://help.instagram.com/182492381886913" className="home_social_icon" target="_blank" rel="noreferrer">
        <i class="uil uil-instagram"></i>
      </a>
      <a
        href="https://www.linkedin.com/in/pravin-amalan-720675193/"
        className="home_social_icon"
        target="_blank" rel="noreferrer"
      >
        <i class="uil uil-linkedin"></i>
      </a>
      <a
        href="https://github.com/pravinamalan"
        className="home_social_icon"
        target="_blank" rel="noreferrer"
      >
        <i class="uil uil-github-alt"></i>
      </a>
    </div>
  );
};

export default Social;
