import project1 from "../assets/healthy food.jpg";
import project2 from "../assets/learning today banner.png";
import project3 from "../assets/beauty buzz.png";
import project4 from "../assets/befit.png";
const ProjectCardData = [
  {
    imgsrc: project1,
    title: "Healthy Food Healthy Life",
    text: "A completed a mobile-responsive shopping website project focusing on the UI design, using HTML and CSS. The website features a visually appealing layout and showcases fresh fruits and vegetables in an eye-catching way.",
    view: "https://food-website-pravinamalan.vercel.app/",
  },
  {
    imgsrc: project2,
    title: "Learning today, Leading Tomorrow",
    text: "During my training time, I developed a complete mobile-responsive website using HTML and CSS. The website, while not featuring any specific functionalities, creating a user interface optimized for mobile devicesDuring",
    view: "https://mentor-pravinamalan.vercel.app/",
  },
  {
    imgsrc: project3,
    title: "Beauty Buzz",
    text: "Create a responsive static website in reactJs for purchase a beauty products",
    view: "https://beautybuzz-pravinamalan.vercel.app/",
  },
  {
    imgsrc: project4,
    title: "BeFit",
    text: "A completed a mobile-responsive Gym website project focusing on the UI design, using HTML and CSS",
    view: "https://gym-site-clone.netlify.app/",
  },
];
export default ProjectCardData;
