import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer-container p-3 row mx-auto" id="footer">
        <div className="col-md-6">
          <ul className="p-0">
            <li className="mb-3 d-flex align-items-center">
              <small>
                <i class="bx bx-home me-2 fs-5"></i>
              </small>
              <span>Chennai</span>
            </li>
            <li className="mt-3 d-flex align-items-center">
              <small>
                <i class="bx bxs-phone-call me-2 fs-5"></i>
              </small>
              <span>9677371766</span>
            </li>
            <li className="mt-3">
              <>
                <i class="bx bxl-gmail me-2 fs-5"></i>
              </>
              <>pravinamalan21@gmail.com</>
            </li>
          </ul>
        </div>
        <div className="col-md-6 d-flex justify-content-lg-end">
          <div>
            <h4>Social Links</h4>
            <div className="home_social d-flex gap-5 mt-3">
              <a
                href="https://help.instagram.com/182492381886913"
                className="home_social_icon"
                target="_blank"
                rel="noreferrer"
              >
                <i class="uil uil-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/pravin-amalan-720675193/"
                className="home_social_icon"
                target="_blank"
                rel="noreferrer"
              >
                <i class="uil uil-linkedin"></i>
              </a>
              <a
                href="https://github.com/pravinamalan"
                className="home_social_icon"
                target="_blank"
                rel="noreferrer"
              >
                <i class="uil uil-github-alt"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
