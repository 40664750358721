import html from "../assets/tech/html.png";
import CSS from "../assets/tech/css.png";
import bootstrap from "../assets/tech/bootstrap.png";
import javascript from "../assets/tech/javascript.png";
import jquery from "../assets/tech/jquery.png";
import react from "../assets/tech/reactjs.png";
import mysql from "../assets/tech/database.png";
import git from '../assets/tech/git.png'
import github from '../assets/tech/github.png'
export const skills = [
 
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: CSS,
  },
  {
    name: "Bootstrap",
    icon: bootstrap,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "Jquery",
    icon: jquery,
  },
  {
    name: "React JS",
    icon: react,
  },

  {
    name: "MySql",
    icon: mysql,
  },
  {
    name: "git",
    icon: git,
  },
  {
    name: "GitHub",
    icon: github,
  },
];
